<template>
  <div class="QuestionsBase">
    <div class="QuestionsBase-info">
      <div class="QuestionsBase-info-position" v-if="!isQuestionTitle">
        {{ position }} {{ ' - ' }}
      </div>

      <div v-if="question !== null" class="QuestionsBase-info-element">
        <div class="QuestionsBase-info-element-title">
          {{ question.text }}
          <span
            v-if="question.mandatory"
            class="QuestionsBase-info-element-title-mandatory"
            >{{ $t('question.mandatory') }}</span
          >
        </div>
        <div v-if="question.type === 'choice_multiple'" class="QuestionsBase-info-element-title-helper">
          {{$t('helperMultipleChoice')}}
        </div>

        <div
          v-if="
            question.help_text !== undefined &&
              question.help_text !== '' &&
              question.help_text !== null
          "
          class="QuestionsBase-info-element-helper"
        >
          {{ question.help_text }}
        </div>

        <div
          v-if="
            question.picture !== undefined &&
              question.picture !== '' &&
              question.picture !== null
          "
        >
          <img class="QuestionsBase-info-element-img" :src="question.picture"  alt="img"/>
        </div>
      </div>
    </div>
    <div v-if="!isQuestionTitle" class="QuestionsBase-question">
      <slot />
    </div>
  </div>
</template>

<script>
'use strict'

import { QUESTION } from '@/utils/consts'

export default {
  name: 'QuestionsBase',
  props: {
    question: {
      type: Object,
      default: null
    },
    fullQuestion: {
      type: Object,
      default: null
    },
    response: {
      type: Object,
      default: null
    },
    position: {
      type: Number,
      required: true
    }
  },
  methods: {
    isCorrect(to, from) {
      if (to === from) return 'PointG'
      else return 'PointR'
    }
  },
  computed: {
    isQuestionTitle() {
      if (this.question.type === QUESTION.TITLE) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.QuestionsBase {
  box-shadow: $card-shadow;
  border-radius: 10px;

  width: 90%;
  margin: 1rem 5% 0 5%;
  padding: 2rem;

  &-point {
    color: #fff;
    background-color: #007bff;
    margin-left: 0.5em;
    padding: 0.5rem;
    align-content: center;
    display: inline-block;
    font-size: 60%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &-info {
    display: flex;

    padding-bottom: 1rem;

    &-position {
      color: $light-grey;
      font-size: 1.4rem;
      font-weight: 300;
      margin-right: 5px;
    }

    &-element {
      display: flex;
      flex-direction: column;

      &-title {
        color: $black-blue;
        font-size: 1.4rem;
        font-weight: 600;
        word-break: break-all;
        // line-height: 32px;

        &-mandatory {
          font-weight: 500;
          font-size: 0.8rem;
          color: red;
        }

        &-helper {
          font-style: italic;
        }
      }

      &-helper {
        color: $helper;
        margin-top: 10px;
        font-weight: 400;
        padding-right: 0.5rem;
      }

      &-img {
        max-width: 50em;
        max-height: 50em;
        width: 100%;
      }
    }
  }

  &-question {
    margin-top: 0.5rem;
    //  padding: 1rem 1rem 1rem 2.4rem;
  }
}
</style>
