export default {
  footer: {
    p1:
      'Vous recevez ce questionnaire car vous avez participé à une formation avec',
    p2: 'qui utilise',
    p3: 'pour ses questionnaires.',
    p4: 'Vos réponses sont anonymes.'
  },
  question: {
    mandatory: '*',
    empty: 'Aucun',
    final_mark: 'Note finale : ',
    min_mark: 'Score minimum',
    point: '{n} point|{n} point|{n} points',
    max_mark:
      'Questionnaire noté sur {n} point|Questionnaire noté sur {n} point |Questionnaire noté sur {n} points',
    mark_as: 'Questionnaire noté sur',
    response: 'Réponse = ',
    finishAlertTitle: "Merci d'avoir répondu !",
    finishAlertText:
      'Vous trouverez ci-dessous les corrections du questionnaire.',
    hideResponse: 'Cacher les explications',
    showResponse: 'Afficher les explications',
    confirm: 'Validé',
    notConfirm: 'Non validé',
    strict:
      'Cette question est en mode strict, toutes les bonnes réponses doivent être choisies pour valider la question'
  },
  helperMultipleChoice: '(Plusieurs choix possibles)',
  responses: 'Réponses',
  unratedAnswer:'Réponse non notée :',
  part: {
    alreadyAnswer: 'Il semblerait que vous avez déjà répondu au questionnaire.',
    error: 'Une erreur est survenue, merci de réessayer plus tard.',
    errorTitle: 'Erreur',
    finishTitle: 'Terminé',
    finishMsg: "Merci d'avoir répondu !",
    footer: {
      sendEnd:
        ' |Vous devez remplir la question obligatoire restante pour envoyer le formulaire.|Vous devez remplir les {n} questions obligatoires restantes pour envoyer le formulaire.',
      sendNext:
        ' |Remplissez la question obligatoire pour continuer.|Remplissez les {n} questions obligatoires pour continuer.',
      previous: 'Précédent',
      next: 'Suivant',
      send: 'Envoyer',
      modal: {
        title: 'Envoyer le questionnaire',
        cancelText: 'Annuler',
        confirmText: 'Oui, Envoyer',
        help: 'Êtes-vous sûr ?'
      },
      error: {
        title: 'Erreur',
        confirmText: 'Fermer',
        help: 'Une erreur est survenue, merci de réessayer plus tard.'
      },
      preview: {
        title: 'Prévisualisation',
        confirmText: 'Fermer',
        help: 'Vous êtes arrivé à la fin de la prévisualisation.'
      }
    },
    header: {
      welcom: 'Bienvenue'
    },
    ie: {
      title: 'Cette application ne supporte pas Internet Explorer.',
      msg:
        "Nous recommandons d'utiliser au moins Microsoft Edge, Google Chrome ou Firefox."
    },
    notFound: {
      msg: "Désolé, le questionnaire n'a pas pu être trouvé."
    }
  },
  error: {
    surveyAlreadyFilled: 'Ce questionnaire a été complété',
    surveyDisabled: 'Ce questionnaire est désactivé',
    surveyExpired: 'Ce questionnaire a expiré'
  }
}
