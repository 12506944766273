import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'

import * as Sentry from '@sentry/vue'

import bienvenue_components from '../node_modules/bienvenue_components/'

Object.values(bienvenue_components).forEach(component => {
  Vue.component(component.name, component)
})

import '@/assets/style/app.scss'

import i18n from './i18n'

Vue.use(VueRouter)

import Routes from './router/index.js'

const router = new VueRouter({
  routes: Routes
})

new Vue({
  store,
  i18n,
  render: h => h(App),
  router: router
}).$mount('#app')

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

async function PackageInfo() {
  const {default: {name, version}} = await import('../package.json');
  return name + '@' + version
}

Sentry.init({
  Vue,
  dsn:
    process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  release: PackageInfo(),

  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    process.env.VUE_APP_SENTRY_URL_TARGET
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

// Ajouter le gestionnaire global pour les promesses non gérées
window.addEventListener('unhandledrejection', function(event) {
  Sentry.captureException(event.reason)
})

// Ajouter également un gestionnaire pour les erreurs globales
window.addEventListener('error', function(event) {
  Sentry.captureException(event.error || event.message)
})


// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin)

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } from 'bootstrap-vue'
Vue.use(CardPlugin)

// This imports directive v-b-scrollspy as a plugin:
import { VBScrollspyPlugin } from 'bootstrap-vue'
Vue.use(VBScrollspyPlugin)

// This imports the dropdown and table plugins
import { DropdownPlugin, TablePlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)
